.App {
  text-align: center;
}

@media screen and (max-width: 500px) {
  .container {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .row {
    display: inline-block;
  }

  .display {
    width: 100%;
    height: 40%;
  }

  .d-flex {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline;
    vertical-align: middle;
  }

  .d-disp {
    width: auto;
    height: 30%;
    background-color: dimgray;
    margin: 1%;
  }

  .d-text {
    color: white;
    font-size: 30px;
  }

  .d-result {
    width: 40%;
    margin-left: 10px ;
    background-color: darkgray;
  }

  .control {
    width: 100%;
    height: 60%;
  }

  .c-row {
    justify-content: center;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
  }

  .c-col {
    padding: 1%;
  }

  .c-btn {
    width: 100px;
    height: 100px;
    font-size: 25px;
    background-color: rgb(128, 128, 128);
  }
}

@media screen and (max-width: 2500px) and (min-width: 500px){
  .container {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .row {
    display: flex;
  }

  .display {
    width: 60%;
    height: 100%;
  }

  .d-flex {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline;
    vertical-align: middle;
  }

  .d-disp {
    width: auto;
    height: 10%;
    background-color: dimgray;
    margin: 1%;
  }

  .d-text {
    color: white;
    font-size: 70px;
  }

  .d-result {
    width: 40%;
    margin-left: 10px ;
    background-color: darkgray;
  }

  .control {
    width: 40%;
    height: 100%;
    position: relative;
  }

  .c-container {
    display: inline;
  }

  .c-row {
    display: flex;
  }

  .c-col {
    padding: 1%;
  }

  .c-btn {
    width: 130px;
    height: 130px;
    font-size: 50px;
    background-color: rgb(128, 128, 128);
  }
}

.c-btn:active {
  background-color: rgb(140,140,140);
}